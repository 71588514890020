import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import Button, { ButtonType } from 'components/Base/Button'
import * as styles from './index.module.less'

interface CookieAuthProps {
  className?: string;
  content?: JSX.Element | string;
  buttonText?: string;
}
const CookieAuth: React.FC<CookieAuthProps> = props => {
  const {
    className,
    content = <>我们想使用 cookie 以便更好了解您对本网站的使用情况。这将有助于改善您今后访问本网站的体验。关于 cookie 的使用，以及如何撤回或管理您的同意，请详见我们的 <a href="/compliance/privacy.html" target="_blank">《隐私政策》</a>。如您点击右侧确认按钮，将视为您同意 cookie 的使用。</>,
    buttonText="确认"
  } = props;
  const [visible, setVisible] = useState<boolean>();

  useEffect(() => {
    var cookieAuth = localStorage.getItem('cookie-authorization');
    if(cookieAuth === '1') {
      setVisible(false);
    } else {
      setVisible(true);
    }
  }, [])

  const onClick = () => {
    localStorage.setItem('cookie-authorization', '1');
    setVisible(false);
  }

  const cnames = classnames(styles.CookieAuth, className, {
    [styles.CookieAuthShow]: visible
  })
  return (
    <div className={cnames}>
      <div className={styles.CookieAuthContent}>
        <p>{content}</p>
        <Button className={styles.CookieAuthButton} btnType={ButtonType.Primary} onClick={onClick} >{buttonText}</Button>
      </div>
    </div>
  );
}

export default CookieAuth;